import React, { createContext, useContext } from 'react';
import { IdentityThemeProvider } from './identity-theme-provider';
export var ApplicationContext = createContext({
    application: null,
});
export var useCreateApplicationContext = function (application) {
    return {
        application: application,
    };
};
export var useApplication = function () {
    return useContext(ApplicationContext);
};
export var ApplicationProvider = function (_a) {
    var children = _a.children, application = _a.application;
    var applicationContext = useCreateApplicationContext(application);
    return (React.createElement(ApplicationContext.Provider, { value: applicationContext },
        React.createElement(IdentityThemeProvider, { application: application }, children)));
};
