import { ApolloClient, DefaultOptions, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
    AuthApiInterface,
    IdentitiesApiInterface,
    IdentityInfoDto,
    Configuration as PermissionsApiConfiguration,
    ResourceAccessTokenRequestDto,
    ResourceAccessTokenResponseDto,
    UsersApiInterface,
} from '@ewego/global-permissions-api';
import {
    CognitoUserInfo,
    ErrorNotification,
    IIdentityProvider,
    IModuleFactoryProps,
    IModuleWorkerWithId,
    IPortalApplicationInstance,
    ModuleCreator,
    ModuleInfo,
    ModulesApiInterface,
    QueryParameters,
    WorkerFactory,
} from '@ewego/portal-frontend-shared';
import { AxiosRequestConfig } from 'axios';
import { render } from 'react-dom';
import { BehaviorSubject, Observable } from 'rxjs';
import { registerApplication, start, unregisterApplication } from 'single-spa';

export interface RootPortalApplicationProps {
    authApi: AuthApiInterface;
    dashboardEntryPoint: string;
    identityApi: IdentitiesApiInterface;
    modulesApi: ModulesApiInterface;
    permissionsApiConfiguration: PermissionsApiConfiguration;
    portalApiBase: string;
    usersApi: UsersApiInterface;
    googleMapsApiKey: string;
}

export const showError = (): void => {
    render(ErrorNotification({}), document.getElementById('root-errors'));
};

const getFlatPermissions = (accessToken: ResourceAccessTokenResponseDto): string[] => {
    const userPermissions = accessToken?.access_grant?.userPermissions ?? [];
    const requestedTenantPermissions = accessToken?.access_grant?.tenant?.permissions ?? [];

    return [...userPermissions, ...requestedTenantPermissions];
};

const isUserAllowedToAccessModule = (moduleInfo: ModuleInfo, flatUserPermissions: string[]): boolean => {
    try {
        const noPermissionsSpecified =
            !moduleInfo?.moduleAccess?.permissions || moduleInfo?.moduleAccess?.permissions?.length === 0;
        if (moduleInfo.moduleAccess.noExtraAccessRequired && noPermissionsSpecified) {
            return true;
        }

        const moduleAccess = moduleInfo.moduleAccess;
        if (moduleAccess) {
            const modulePermissions = moduleAccess.permissions;
            // Check whether the moduleAccess was defined properly for a set of required permissions
            if (
                flatUserPermissions &&
                modulePermissions &&
                moduleAccess.noExtraAccessRequired === false &&
                modulePermissions.length > 0
            ) {
                // Check whether a user has ALL required permission for at least a single conjunction (PA1 && PA2 && ... && PAN) defined in the module.
                // DNF like => (PA1 && ... && PN1) || ... || (PM1 && ... && PMN)
                return modulePermissions.some(conjunctions =>
                    conjunctions.every(value => flatUserPermissions.includes(value))
                );
            }
        }
    } catch (e) {
        console.error(e);
    }
    return false;
};

const defaultApolloClientOptions: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};

export class RootPortalApplication implements IPortalApplicationInstance {
    private modules: ModuleInfo[];
    private readonly applicationsRegistered: Array<string>;
    public readonly authToken: BehaviorSubject<string | null>;
    public readonly resourceAccessToken: BehaviorSubject<ResourceAccessTokenResponseDto | null>;
    public readonly modulesSubject: BehaviorSubject<ModuleInfo[]>;
    public readonly workers: BehaviorSubject<IModuleWorkerWithId[]>;
    private readonly identity: BehaviorSubject<IdentityInfoDto | null>;
    private readonly identityProvider: BehaviorSubject<IIdentityProvider>;
    public readonly cognitoUserInfo: BehaviorSubject<CognitoUserInfo>;
    public readonly logoPath = `${process.env.ROOT_ASSET_BASE}/domains/portal.ewe-go.de/logo.svg`;
    public readonly apolloClient: ApolloClient<NormalizedCacheObject>;

    constructor(readonly props: RootPortalApplicationProps) {
        this.modules = [];
        this.applicationsRegistered = [];
        this.modulesSubject = new BehaviorSubject<ModuleInfo[]>([]);
        this.workers = new BehaviorSubject<IModuleWorkerWithId[]>([]);
        this.authToken = new BehaviorSubject<string | undefined>(null);
        this.resourceAccessToken = new BehaviorSubject<ResourceAccessTokenResponseDto | undefined>(null);
        this.identity = new BehaviorSubject<IdentityInfoDto>(null);
        this.identityProvider = new BehaviorSubject<IIdentityProvider>(null);
        this.cognitoUserInfo = new BehaviorSubject<CognitoUserInfo>(null);
        this.apolloClient = new ApolloClient({
            link: new HttpLink({ uri: this.props.portalApiBase }),
            cache: new InMemoryCache(),
            defaultOptions: defaultApolloClientOptions,
        });
    }

    public getGoogleMapsApiKey(): string {
        return this.props.googleMapsApiKey;
    }

    getIdentityProvider(): Observable<IIdentityProvider> {
        return this.identityProvider;
    }

    setIdentityProvider(provider: IIdentityProvider): void {
        this.identityProvider.next(provider);
    }

    public async init(): Promise<void> {
        const { dashboardEntryPoint } = this.props;

        registerApplication({
            name: 'dashboard',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            app: async () => System.import(dashboardEntryPoint),
            activeWhen: ['/'],
            customProps: {
                ...this.getModuleProps(),
            },
        });
        this.applicationsRegistered.push('dashboard');

        start({
            urlRerouteOnly: true,
        });

        await this.fetchIdentity();

        this.authToken.subscribe(async currentAuthToken => {
            if (!currentAuthToken) {
                return;
            }

            const resourceTokenRequest: ResourceAccessTokenRequestDto = {
                tenantId: 'ego',
            };

            try {
                // request RAT
                const { data: resourceAccessTokenResponse } = await this.props.authApi.getResourceAccessToken(
                    resourceTokenRequest,
                    {
                        headers: {
                            Authorization: `Bearer ${currentAuthToken}`,
                        },
                    }
                );
                const resourceAccessToken = resourceAccessTokenResponse.access_token;
                this.props.permissionsApiConfiguration.baseOptions = {
                    ...this.props.permissionsApiConfiguration.baseOptions,
                    headers: {
                        ...this.props.permissionsApiConfiguration?.baseOptions?.headers,
                        Authorization: `Bearer ${resourceAccessToken}`,
                    },
                };

                const flatUserPermissions = getFlatPermissions(resourceAccessTokenResponse);

                // request modules (MFs) with RAT
                await this.initModules(resourceAccessToken, flatUserPermissions);

                // set RAT
                this.resourceAccessToken.next(resourceAccessTokenResponse);
            } catch (error) {
                if (error?.response?.status === 400 || error?.response?.status === 401) {
                    localStorage.clear();
                    location.reload();
                } else if (error?.response?.status === 404) {
                    // request modules (MFs) with just UAT
                    await this.initModules(currentAuthToken, []);
                } else {
                    showError();
                    throw error;
                }
            }
        });
    }

    private async initModules(accessToken: string, flatUserPermissions: string[]) {
        const { modulesApi } = this.props;

        const httpLink = new HttpLink({ uri: this.props.portalApiBase });
        const authLink = setContext((_, { headers }) => {
            return {
                headers: {
                    ...headers,
                    authorization: accessToken ? `Bearer ${accessToken}` : '',
                },
            };
        });
        this.apolloClient.setLink(authLink.concat(httpLink));

        const modulesRequestHeaders: AxiosRequestConfig = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };

        const { data: modules } = await modulesApi.getAllModules(modulesRequestHeaders);

        this.modules = modules;
        this.modulesSubject.next(modules);

        const workers = this.workers.getValue();

        for (const moduleInfo of this.modules) {
            if (isUserAllowedToAccessModule(moduleInfo, flatUserPermissions)) {
                if (moduleInfo.worker && workers.findIndex(worker => worker.id === moduleInfo.id) === -1) {
                    const workerFactory = (await System.import(moduleInfo.worker)).default as WorkerFactory;
                    const worker = workerFactory({
                        application: this,
                    });
                    workers.push({
                        id: moduleInfo.id,
                        worker,
                    });
                }

                if (!this.applicationsRegistered.includes(moduleInfo.id)) {
                    try {
                        this.applicationsRegistered.push(moduleInfo.id);

                        const moduleFactory = (await System.import(moduleInfo.path)).default as ModuleCreator;

                        registerApplication({
                            name: moduleInfo.id,
                            app: async () => {
                                return moduleFactory(moduleInfo.id);
                            },
                            activeWhen: moduleInfo.activeWhen,
                            customProps: {
                                ...this.getModuleProps(),
                            },
                        });
                    } catch (e) {
                        const registeredApplicationIndex = this.applicationsRegistered.indexOf(moduleInfo.id);
                        this.applicationsRegistered.splice(registeredApplicationIndex, 1);
                        throw e;
                    }
                }
            } else {
                if (this.applicationsRegistered.includes(moduleInfo.id)) {
                    await unregisterApplication(moduleInfo.id);

                    const registeredApplicationIndex = this.applicationsRegistered.indexOf(moduleInfo.id);
                    const workerIndex = workers.findIndex(worker => worker.id === moduleInfo.id);

                    workers.splice(workerIndex, 1);
                    this.applicationsRegistered.splice(registeredApplicationIndex, 1);
                }
            }
        }
        this.workers.next(workers);
    }

    private getModuleProps(): IModuleFactoryProps {
        return {
            application: this,
        };
    }

    async fetchIdentity(): Promise<void> {
        let domainName = window.location.host.startsWith('localhost') ? process.env.DOMAIN_NAME : window.location.host;

        // Overwrite the domainName to query the identity info against, when a domain query parameter is set
        if (window.URLSearchParams) {
            const queryParams = new window.URLSearchParams(window.location.search);
            if (queryParams.has(QueryParameters.DOMAIN)) {
                const domain = queryParams.get(QueryParameters.DOMAIN);
                if (domain) {
                    domainName = domain;
                }
            }
        }
        const { data: identityInfo } = await this.props.identityApi.getIdentityByDomainName(domainName);

        if (identityInfo) {
            this.props.permissionsApiConfiguration.baseOptions = {
                ...this.props.permissionsApiConfiguration?.baseOptions,
                headers: {
                    ...this.props.permissionsApiConfiguration?.baseOptions?.headers,
                    Identity: identityInfo.id,
                },
            };
            this.identity.next(identityInfo);
        }
    }

    getIdentity(): Observable<IdentityInfoDto | null> {
        return this.identity.asObservable();
    }

    getUsersApi(): UsersApiInterface {
        return this.props.usersApi;
    }

    setAuthToken(token: string): void {
        this.authToken.next(token);
    }

    setCognitoUserInfo(cognitoUserInfo: CognitoUserInfo): void {
        this.cognitoUserInfo.next(cognitoUserInfo);
    }
}
