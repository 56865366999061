import { defaultThemeOptions } from '@ewego/portal-components';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material';
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
var IdentityThemeContext = createContext({
    identity: null,
    user: null,
    cognitoUserInfo: undefined,
    theme: createTheme(defaultThemeOptions),
});
var useCreateIdentityThemeContext = function (application) {
    var _a = useState(null), identity = _a[0], setIdentity = _a[1];
    var _b = useState(null), user = _b[0], setUser = _b[1];
    var _c = useState(), cognitoUserInfo = _c[0], setCognitoUserInfo = _c[1];
    var identityProviderSubscription = useRef(null);
    useEffect(function () {
        var identitySubscription = application.getIdentity().subscribe(function (nextIdentity) {
            setIdentity(nextIdentity);
        });
        return function () {
            identitySubscription.unsubscribe();
        };
    }, [application]);
    useEffect(function () {
        var identitySubscription = application.getIdentityProvider().subscribe(function (nextProvider) {
            if (identityProviderSubscription.current) {
                identityProviderSubscription.current.unsubscribe();
            }
            if (nextProvider) {
                identityProviderSubscription.current = nextProvider.getCurrentUser().subscribe(function (nextUser) {
                    setUser(nextUser);
                });
            }
        });
        return function () {
            identitySubscription.unsubscribe();
        };
    }, [application]);
    useEffect(function () {
        var emailSubscription = application.cognitoUserInfo.subscribe(function (nextCognitoUserInfo) {
            setCognitoUserInfo(nextCognitoUserInfo);
        });
        return function () {
            emailSubscription.unsubscribe();
        };
    }, [application]);
    var theme = useMemo(function () { return createTheme(defaultThemeOptions); }, []);
    return {
        identity: identity,
        user: user,
        cognitoUserInfo: cognitoUserInfo,
        theme: theme,
    };
};
export var useIdentityContext = function () {
    return useContext(IdentityThemeContext);
};
export var IdentityThemeProvider = function (_a) {
    var children = _a.children, application = _a.application;
    var themeContext = useCreateIdentityThemeContext(application);
    return (React.createElement(IdentityThemeContext.Provider, { value: themeContext },
        React.createElement(StyledEngineProvider, { injectFirst: true },
            React.createElement(ThemeProvider, { theme: themeContext.theme }, children))));
};
