import {
    AuthApi,
    IdentitiesApi,
    Configuration as PermissionsApiConfiguration,
    UsersApi,
} from '@ewego/global-permissions-api';
import { ModulesApi, PortalApiConfiguration, ROOT_APP_CONFIG_ID } from '@ewego/portal-shared-types';
import { RootPortalApplication, showError } from './application';

let injectedConfig: Record<string, string | never> = {};

const injectedScript = document.getElementById(ROOT_APP_CONFIG_ID);
if (injectedScript) {
    try {
        injectedConfig = JSON.parse(injectedScript.textContent);
    } catch (e) {
        console.error(`Unable to parse injected config due to`, e);
    }
}

const portalApiBase = process.env.API_BASE ?? injectedConfig.API_BASE;
const portalModulesApiBase = process.env.MODULES_API_BASE ?? injectedConfig.MODULES_API_BASE;
const permissionsApiBase = process.env.PERMISSIONS_BASE ?? injectedConfig.PERMISSIONS_BASE;
const googleMapsApiKey = process.env.GOOGLE_MAPS_API_KEY ?? injectedConfig.GOOGLE_MAPS_API_KEY;

const portalModulesApiConfiguration = new PortalApiConfiguration({
    basePath:
        (portalModulesApiBase === '' ? `${window.location.origin}/api` : portalModulesApiBase) ?? portalModulesApiBase,
});

const permissionsApiConfiguration = new PermissionsApiConfiguration({
    basePath: permissionsApiBase,
});

const identityApi = new IdentitiesApi(permissionsApiConfiguration);
const usersApi = new UsersApi(permissionsApiConfiguration);
const authApi = new AuthApi(permissionsApiConfiguration);

const rootApp = new RootPortalApplication({
    authApi,
    dashboardEntryPoint: process.env.DASHBOARD_URL,
    identityApi,
    modulesApi: new ModulesApi(portalModulesApiConfiguration),
    permissionsApiConfiguration,
    portalApiBase,
    usersApi,
    googleMapsApiKey,
});

rootApp
    .init()
    .then(() => {
        console.debug('Portal loaded', rootApp);
    })
    .catch(error => {
        showError();
        throw error;
    });
